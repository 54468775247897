export default {
  2019: [
    { time: '16:30', event: 'Doors Open', stage: null },
    { time: '17:00', event: 'Nattskiftet', stage: 'small stage' },
    { time: '18:00', event: 'Severe Illusion', stage: 'big stage' },
    { time: '19:15', event: 'ELM', stage: 'small stage' },
    { time: '20:15', event: 'Die Krupps', stage: 'big stage' },
    { time: '22:30', event: 'Front 242', stage: 'big stage' },
    { time: '00:00', event: 'Presto Fervant', stage: 'small stage' },
    { time: '02:00', event: 'Closing', stage: null },
  ],
  2024: [
    { time: '18:00', event: 'Doors open', stage: null },
    { time: '18:45', event: 'Arvsynd', stage: null },
    { time: '19:45', event: 'No Sleep by the Machine', stage: null },
    { time: '20:50', event: 'Alvar', stage: null },
    { time: '21:50', event: 'Dive', stage: null },
    { time: '23:10', event: "Lucifer's Aid", stage: null },
    { time: '01:00', event: 'Doors close', stage: null },
  ],
};
