import React from 'react';
import PropTypes from 'prop-types';
import Flag from '../Flag/Flag';
import styles from './Artist.module.scss';
import soundCloudLogo from '../../images/sound-cloud.png';
import placeholderImage from '../../images/gear.jpg';

const Artist = ({
  countryCode,
  description,
  id,
  image,
  name,
  openModal,
  soundCloud,
  soundCloudClick,
  spotify,
  spotifyClick,
  subtitle,
  showReadMore = true,
  links,
}) => {
  const handleButtonClick = () => {
    openModal(id);
  };
  const imageUrl = image ? image : placeholderImage;
  return (
    <div className={styles.container}>
      <div className={styles.imageWrapper}>
        <img alt={name} className={styles.image} src={imageUrl} />
      </div>
      <div className={styles.artistHeader}>
        <div className={styles.name}>
          {name}
          <Flag countryCode={countryCode} />
        </div>
      </div>
      <div className={styles.description}>
        <h2 className={styles.subtitle}>{subtitle}</h2>
        {
          <>
            <p className={styles.shortDescription}>{description}</p>
            {showReadMore && (
              <button className={styles.readMoreButton} onClick={handleButtonClick}>
                <span className={styles.readMoreText}>Read more &raquo;</span>
              </button>
            )}
          </>
        }
      </div>
      {((!!links && !!Object.keys(links).length) || !!spotify) && (
        <div className={styles.footerLinks}>
          <p className={styles.linkHeader}>Artist links</p>
          {!!links &&
            Object.keys(links)?.map((key, index) => (
              <div key={`${name} ${key}`} style={{ display: 'inline-block' }}>
                <a target="_blank" rel="noopener noreferrer" className={styles.artistLink} href={links?.[key]}>
                  {key.slice(0, 1).toUpperCase()}
                  {key.slice(1)}
                </a>
                {index < Object.keys(links).length - 1 && <span className={styles.artistLink}>|</span>}
              </div>
            ))}
          <span>
            {spotify && (
              <>
                {!!links && <span className={styles.artistLink}>|</span>}
                <span className={styles.spotifyLink} onClick={() => spotifyClick(spotify)}>
                  Listen on Spotify
                </span>
              </>
            )}
            {soundCloud && (
              <img
                alt="soundcloud logo"
                className={styles.soundCloudLogo}
                src={soundCloudLogo}
                onClick={() => soundCloudClick(soundCloud)}
              />
            )}
          </span>
        </div>
      )}
    </div>
  );
};

Artist.propTypes = {
  countryCode: PropTypes.string.isRequired,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]).isRequired,
  id: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  links: PropTypes.shape({ facebook: PropTypes.string, bandcamp: PropTypes.string }),
  name: PropTypes.string.isRequired,
  openModal: PropTypes.func,
  showReadMore: PropTypes.bool,
  soundCloud: PropTypes.string,
  soundCloudClick: PropTypes.func,
  spotify: PropTypes.string,
  spotifyClick: PropTypes.func,
  subtitle: PropTypes.string,
};

export default Artist;
