export default [
  { name: '32Crash', years: ['2012'] },
  { name: 'A Split Second', years: ['2014', '2011'] },
  { name: 'Absolute Body control', years: ['2021'] },
  { name: 'Agent Side Grinder', years: ['2021'] },
  { name: 'Anna Öberg & Härsk', years: ['2021'] },
  { name: 'Alvar', years: ['2017', '2024'] },
  { name: 'Apoptygma Berzerk', years: ['2015'] },
  { name: 'Armageddon Dildos', years: ['2016'] },
  { name: 'Arvsynd', years: ['2024'] },
  { name: 'Autodafeh', years: ['2010'] },
  { name: 'Aux Animaux', years: ['2023'] },
  { name: 'Borghesia', years: ['2014'] },
  { name: 'Brides of the Black Room', years: ['2023'] },
  { name: 'Celldöd', years: ['2022'] },
  { name: 'Container 90', years: ['2013', '2023'] },
  { name: 'Covenant', years: ['2017'] },
  { name: 'Das Ich', years: ['2013'] },
  { name: 'Deutsch Amerikanische Freundschaft', years: ['2016', '2023'] },
  { name: 'Diesel Dudes', years: ['2018'] },
  { name: 'Die Krupps', years: ['2019'] },
  { name: 'Dive', years: ['2016', '2024'] },
  { name: 'Dupont', years: ['2014'] },
  { name: 'EkoBrottsMyndigheten', years: ['2014'] },
  { name: 'ELM', years: ['2019'] },
  { name: 'Emmon', years: ['2022'] },
  { name: 'Fixmer/McCarthy', years: ['2017'] },
  { name: 'Front 242', years: ['2010', '2019'] },
  { name: 'Frontal', years: ['2017'] },
  { name: 'Healed/NerWrak', years: ['2013'] },
  { name: 'Jäger 90', years: ['2016'] },
  { name: 'Karin Park', years: ['2015'] },
  { name: 'Kommando XY', years: ['2011'] },
  { name: 'Kunt', years: ['2023'] },
  { name: 'Leaether Strip', years: ['2018', '2023'] },
  { name: 'Leroy Se Meurt', years: ['2022'] },
  { name: "Lucifer's Aid", years: ['2024'] },
  { name: 'Menticide', years: ['2017'] },
  { name: 'Metroland', years: ['2016'] },
  { name: 'Nattskiftet', years: ['2019'] },
  { name: 'NZ', years: ['2015'] },
  { name: 'No Sleep by the Machine', years: ['2010', '2024'] },
  { name: 'NordarR', years: ['2012'] },
  { name: 'Oldschool Union', years: ['2012'] },
  { name: 'Orange Sector', years: ['2011'] },
  { name: 'Pankow', years: ['2013'] },
  { name: 'Parade Ground', years: ['2011'] },
  { name: 'Portion Control', years: ['2015', '2022'] },
  { name: 'Pouppée Fabrikk', years: ['2013', '2010'] },
  { name: 'Presto Fervant', years: ['2019'] },
  { name: 'Rein', years: ['2016'] },
  { name: 'Roya', years: ['2018'] },
  { name: 'Schwefelgelb', years: ['2011'] },
  { name: 'Severe Illusion', years: ['2019'] },
  { name: 'Spark!', years: ['2013', '2010'] },
  { name: 'Spetsnaz', years: ['2012'] },
  { name: 'S.P.O.C.K', years: ['2018'] },
  { name: 'Spit Mask', years: ['2021'] },
  { name: 'Stockholm Wrecking Crew', years: ['2010'] },
  { name: 'Sturm Café', years: ['2015', '2011'] },
  { name: 'Test Dept', years: ['2022'] },
  { name: 'The Cassandra Complex', years: ['2014'] },
  { name: 'The Invincible Spirit', years: ['2012'] },
  { name: 'The Juggernauts', years: ['2014'] },
  { name: 'The Klinik', years: ['2011'] },
  { name: 'The Neon Judgement', years: ['2013'] },
  { name: 'The Operating Tracks', years: ['2015', '2021'] },
  { name: 'The Young Gods', years: ['2018'] },
  { name: 'Tyske Ludder', years: ['2017', '2010'] },
  { name: 'Underviewer', years: ['2018'] },
  { name: 'Vomito Negro', years: ['2012'] },
  { name: 'Wulfband', years: ['2014'] },
  { name: 'Zweite Jugend', years: ['2022'] },
];
