import { artists, djs, schedule, info, settings } from '../data';

const { activeYear } = settings;

export const getCurrent = (key) => {
  const current = info.find((data) => data.yearString === activeYear);

  return current[key];
};

export const getCurrentArtists = () =>
  artists.filter((artist) => {
    if (artist?.year === activeYear && artist.published) {
      return artist;
    }

    return null;
  });

export const getCurrentDjs = () => {
  return djs.filter((dj) => {
    if (dj?.year === activeYear && dj.published) {
      return dj;
    }

    return null;
  });
};

export const getCurrentSchedule = () => {
  if (activeYear in schedule) {
    return schedule[activeYear];
  }
  return null;
};

export const headlineSort = (a, b) => {
  if (a.headliner && !b.headliner) return -1;
  if (!a.headliner && b.headliner) return 1;
  return 0;
};

export const isThePartyOver = () => {
  const changeDate = new Date(new Date(getCurrent('dateString')).getTime() + 1000 * 60 * 60 * 25);
  const currentDate = new Date();
  return currentDate > changeDate;
};
